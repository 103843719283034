.wrapperForListPage {
  width: 100%;
  position: relative;
}

.wrapperForVehiclePage {
  height: 12.5rem;
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 4rem;
}

.miniMap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.625rem;
  height: 3.625rem;
  border-radius: 0.8rem;
  position: absolute;
  right: 2px;
  top: 5rem;
  z-index: 2000;
  cursor: pointer;
  border: 4px solid rgba(232, 234, 246, 100%);
  background-image: url('../../../assets/mapLogo.png');
  background-size: cover;
}

.miniMapChild {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.miniMapChild:hover::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.6rem;
  background-color: rgba(15, 19, 36, 60%);
}

.slidesMap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.625rem;
  height: 3.625rem;
  border-radius: 0.8rem;
  position: absolute;
  z-index: 2000;
  cursor: pointer;
  border: 4px solid rgba(232, 234, 246, 100%);
  background-image: url('../../../assets/mapLogo.png');
  background-size: cover;
  bottom: 0.5rem;
  right: 0.5rem;
}

.slidesMapChild {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.slidesMapChild:hover::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 0.6rem;
  background-color: rgba(15, 19, 36, 60%);
}

.slidesMapExitIconContainer {
  background-color: #fff !important;
  border-radius: 0.5rem !important;
  position: absolute;
}

.miniMapExitIconContainer {
  background-color: #fff !important;
  border-radius: 0.5rem !important;
  position: absolute;
}

.mapLogo {
  max-width: 100%;
  height: auto;
}

.mapContainer {
  width: 100%;
  height: 100%;
}

.centeringWidgetContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3.5rem;
  right: 0.5rem;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 0.25rem;
  width: 2.125rem;
  height: 2.125rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 #14151a0d;
}

.allDevicesModeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 11.5rem;
  right: 0.5rem;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 0.25rem;
  width: 2.125rem;
  height: 2.125rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 #14151a0d;
}

.allDevicesModeContainerPopupActive {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 24.5rem;
  right: 0.5rem;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 0.25rem;
  width: 2.125rem;
  height: 2.125rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 #14151a0d;
}

.settingsContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 2.125rem !important;
  height: 2.125rem !important;
  border-radius: 0.25rem !important;
  background-color: rgba(245, 245, 245, 100%);
  border-bottom: none !important;
  color: rgba(0, 0, 0, 87%) !important;
  font-size: 20px !important;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 999;
}

.slidesMiniMap {
  position: absolute !important;
  z-index: 9999;
  width: 200px !important;
  height: 200px;
  right: 1rem;
  bottom: 2.5rem;
}

.slidesMiniMapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 0.25rem;
  width: 2.125rem;
  height: 2.125rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 #14151a0d;
}

.miniMapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 3.5rem;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 0.25rem;
  width: 2.125rem;
  height: 2.125rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 #14151a0d;
}

.unPinMapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3.5rem;
  right: 0.5rem;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 0.25rem;
  width: 2.125rem;
  height: 2.125rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 #14151a0d;
}

.pinMapContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 0.25rem;
  width: 2.125rem;
  height: 2.125rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 #14151a0d;
}

.settingsWidget {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 16.25rem;
  height: 17.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #fff;
  border: 1px solid #dee0e3;
  box-shadow: 0 2px 4px -1px #14151a0f;
  padding: 1.25rem;
  border-radius: 0.25rem;
  z-index: 1000;
}

.settingsWidgetHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layerImagesContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.layerImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.layerImage {
  width: 5rem;
  height: 5rem;
  border: 2px solid #fff;
  padding: 4px;
  border-radius: 12px;
  cursor: pointer;
}

.metrics {
  display: flex;
  justify-content: start;
  gap: 0.25rem;
}

.metric {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.625rem;
  height: 1.75rem;
  padding: 4px 6px;
  border-radius: 8px;
  cursor: pointer;
}

.clusterMarker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.5rem !important;
  width: 2.5rem !important;
  background-color: orange;
  font-size: 1rem;
  border-radius: 50%;
  color: white;
  font-family: Inter, sans-serif;
}

.pinMarker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.875rem !important;
  height: 2.875rem !important;
  background-color: #26bd6c;
  border-radius: 0 50% 50%;
}

.mapOpenControl {
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  background-color: #000;
  color: #fff;
  border-radius: 24px;
  width: 1.5rem;
}

.mapCloseControl {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 99999;
  top: -0.75rem;
  right: -0.75rem;
  background-color: #000;
  border-radius: 50%;
  padding: 0.2rem;
}

.zoomControlContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  z-index: 999;
  right: 0.5rem;
  bottom: 6rem !important;
}

.zoomControlContainerPopupActive {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  z-index: 999;
  right: 0.5rem;
  bottom: 19.1rem !important;
}

.zoomControlItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.125rem;
  height: 2.125rem;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 0.25rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 #14151a0d;
}

.slidesMiniMapCoordinatesContainer {
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  cursor: pointer;
  width: 100%;
  background-color: #000;
  border-radius: 0.25rem;
}

.miniMapCoordinatesContainer {
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  cursor: pointer;
  width: 100%;
}

.miniMapCoordinatesValue {
  color: rgba(255, 255, 255, 38%) !important;
  letter-spacing: 1px;
  line-height: 32px;
  font-size: 0.7rem !important;
}
