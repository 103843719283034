.noAccessContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 30rem auto;
  border-radius: 0.5rem;
  border: 1px solid black;
  width: 30rem;
  padding: 2rem;
  text-align: center;
}

.backToLogin {
  width: fit-content;
}
