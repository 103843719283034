.vehicleContainer {
  display: flex;
  background-color: #212121;
}

.vehicleContainerLeftSide {
  height: 100%;
}

.commonControlsContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.commonControls {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: fit-content;
  background-color: rgba(45, 45, 45, 100%);
  border-radius: 50px;
  height: 3.5rem;
}

.startEngineStart {
  position: relative;
  background-color: rgba(102, 187, 106, 100%) !important;
}

.manualControlStart {
  position: relative;
  background-color: rgba(244, 67, 54, 100%) !important;
}

.startEngineStop,
.manualControlStop {
  position: relative;
  background-color: inherit !important;
}

.startEngineStop:hover,
.manualControlStop:hover,
.lightsOn:hover,
.lightsOff:hover {
  background-color: rgba(255, 255, 255, 8%) !important;
}

.sweepersType {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 12000;
  bottom: 4rem;
  background: rgba(255, 255, 255);
  border-radius: 0.5rem;
  padding: 0.2rem;
  gap: 0.5rem;
}

.sweepersTypeItem {
  background-color: rgba(45, 45, 45, 100%);
  padding: 0.25rem 0.5rem;
  border: 1px solid rgba(45, 45, 45, 100%);
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
}

.sweepersNonTypeItem {
  border: 1px solid rgba(45, 45, 45, 100%);
  border-radius: 0.5rem;
  background-color: #fff;
  color: #000;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.controlItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  position: relative;
}

.controllerIcon {
  width: 20px;
}

.wipers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 1.5rem;
  position: relative;
}

.wipers:hover {
  background-color: rgba(255, 255, 255, 8%);
}

.commandText {
  position: absolute;
  bottom: -43%;
  color: #fff;
  font-size: 0.625rem !important;
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 5rem;
  max-width: fit-content;
}

.additionalInfoContainer {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ffffff24;
}

.additionalInfoContainerInner {
  position: relative;
  height: 100%;
}

.additionalInfoBlock {
  height: 40%;
}

.primaryMetricContainer {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.primaryMetricContainer1 {
  display: flex;
  flex-direction: column;
}

.primaryMetricValue {
  color: #fff;
  letter-spacing: 0 !important;
  line-height: 28px !important;
}

.primaryMetric {
  color: rgba(255, 255, 255, 38%);
  font-weight: 500;
  letter-spacing: 0 !important;
  line-height: 16px !important;
}

.primaryMetric1 {
  color: #fff;
  letter-spacing: 0 !important;
  line-height: 16px !important;
}

.secondaryMetricContainer {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.rollPitchContainerInner {
  display: flex;
  flex-direction: column;
}

.secondaryMetricContainer1 {
  display: flex;
  align-items: center;
  gap: 0.2125rem;
}

.secondaryMetric {
  color: rgba(255, 255, 255, 38%);
  font-weight: 500;
  line-height: 20px !important;
  letter-spacing: 0 !important;
}

.secondaryMetric1 {
  color: rgba(255, 255, 255, 38%);
  font-weight: 400 !important;
  line-height: 20px !important;
}

.rollPitchContainer {
  display: flex;
  justify-content: space-between;
}

.metricsBlockMain {
  position: absolute;
  width: 100%;
  bottom: 72px;
}

.metricsBlock {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.rollValue,
.pitchValue {
  color: #fff;
  font-size: 13px !important;
  line-height: 14px !important;
  letter-spacing: 0 !important;
}

.metric {
  display: flex;
  align-items: center;
  gap: 4px;
}

.armButton {
  position: relative;
  background-color: inherit !important;
  pointer-events: none;
}

.armDot {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.textArea {
  font-family: Inter, sans-serif;
  font-size: 0.8rem;
  outline: none;
  border: 1px solid #b0b8c4;
  border-radius: 8px;
  min-width: 700px;
  min-height: 100px;
  padding: 8px 12px;
}

.textArea:focus {
  border-color: #2262c2;
}

.searchInput {
  border-radius: 12px;
  margin: 13px 0 !important;
  border: 1px solid #424242 !important;
}

/* .searchInput:hover {
} */

.alertContainer {
  position: fixed;
  z-index: 1000;
  top: 5rem;
  left: 5rem;
  max-width: 20rem;
  height: 5rem;
  align-items: center;
}

.controlsVisibilityContainer {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  z-index: 1001;
}

.fullWidthHeight {
  width: 100%;
  height: 100%;
}

.startEngineIconWrapper {
  width: 1.5rem;
  height: 1.5rem;
}

.controlsVisibilityV1 {
  width: 7.625rem;
  height: 7.625rem;
}

.batteryContainer {
  display: flex;
  align-items: flex-end;
  gap: 0.25rem;
}

.battery {
  margin-top: 20px;
  width: 20px;
  height: 15px;
  background: #eee;
  border: 2px solid #757575;
  position: relative;
}

.batteryLevel {
  background: #757575;
  position: absolute;
  bottom: 1px;
  left: 1px;
  height: 13px;
  transition: width 0.3s ease;
}

.battery::after {
  content: '';
  position: absolute;
  top: 3px;
  right: -6px;
  display: block;
  width: 3px;
  height: 8px;
  background: #757575;
}

.batteryTextColor {
  color: #fafafa;
  margin: 0;
}

.compassContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
}

.compassNavigation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.compass {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
}

.compass:hover {
  background-color: rgba(255, 255, 255, 8%);
}

.gamepadContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0 0 2rem;
}

.snackbarContainer {
  position: absolute;
  top: 10px;
  left: 50% !important;
  transform: translateX(-50%);
}
