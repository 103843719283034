.mediaListIcon {
  position: absolute !important;
  right: 11rem;
  bottom: 1rem;
}

.mediaListIcon:hover {
  background-color: rgba(255, 255, 255, 8%) !important;
}

.mediaListIconInnerWrapper {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.mediaListContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: 90vh;
  background-color: #fff;
  border: 2px solid #000;
  padding: 16px;
}

.mediaListCloseIcon {
  position: absolute !important;
  right: 1rem;
  z-index: 1;
}

.mediaListNoData {
  text-align: center;
  font-style: italic;
}

.mediaListLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 70%;
}

.mediaListSingleVideo {
  object-fit: cover;
  border-radius: 4px;
}

.singlePhotoContainer {
  position: relative;
  height: 220px;
}

.singlePhotoCheckbox {
  position: absolute !important;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: #000 !important;
}

.singlePhotoLocalSelected {
  position: absolute !important;
  bottom: 0.3rem;
  right: 0.3rem;
  background-color: #000 !important;
}

.singlePhotoHoverActions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute !important;
  top: 0.5rem;
  right: 0.5rem;
}

.singlePhotoDownload,
.singlePhotoExpand,
.singlePhotoDelete {
  background-color: #000 !important;
}

.chipAction {
  cursor: pointer;
}

.mediaListSingleImage {
  border-radius: 4px;
  cursor: pointer;
}

.selectedLocalPhotoContainer {
  position: relative;
  height: 110px;
}

.fullWidthHeight {
  width: 100%;
  height: 100%;
}

.mediaListSingleImageFullScreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.videoListContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  overflow: scroll;
  height: 74vh;
}

.photosListForAction {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.2rem;
  overflow: scroll;
  min-height: 200px;
  height: fit-content;
  max-height: 600px;
}

.tabsContainer {
  width: 100%;
  background-color: #fff;
  margin-bottom: 2rem;
}

.videoDescription {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.nonVideoDescription {
  display: flex;
  justify-content: center;
}

.deletePopover {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.deletePopoverButtons {
  display: flex;
  justify-content: end;
}

.datePickers {
  display: flex;
  justify-content: center;
  width: 40%;
  gap: 0.5rem;
}

.mediaActions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  margin-bottom: 2rem;
}

.singlePhotoIcon {
  color: #fff;
}
