.fallbackContainer {
  height: 100vh;
  width: 100vw;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingText {
  color: black;
  margin-top: 1rem !important;
}
