.appBar {
  height: 62px;
  padding: 12px 32px;
  background-color: rgba(33, 33, 33, 100%) !important;
  z-index: 1000;
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 14%);
}

.toolbar {
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  min-height: fit-content !important;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menuItem {
  gap: 8px;
  padding-left: 12px;
}

.pageTitle {
  color: rgba(255, 255, 255, 70%);
  cursor: pointer;
}

.battery {
  font-size: 0.875rem !important;
}

.activeVehicleName {
  color: #fff;
  margin-left: -6px;
}

.headerRightSide {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.currentTimeContainer {
  display: flex;
  gap: 0.8rem;
}

.currentDate {
  color: rgba(255, 255, 255, 56%);
}

.currentTime {
  color: #fff;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 2rem;
}
