.iframe {
  width: 470px;
  height: 360px;
  border: none;
  border-radius: 4px;
}

.iframeContainer {
  text-align: center;
}

.licenseText {
  padding-bottom: 24px;
}

.agreementText {
  display: flex;
  gap: 10px;
  user-select: none;
  padding: 10px 0 25px;
}

.buttonsContainer {
  display: flex;
  gap: 25px;
}

.buttonContinue {
  flex: 1;
}
