.onsHistoryContainer {
  padding: 1rem 4rem;
  background-color: rgba(33, 33, 33, 100%);
  min-height: 91.5vh;
}

.createRouteButton {
  background-color: rgba(144, 202, 249, 100%) !important;
  color: black !important;
  width: 15rem !important;
}

.onsCreateContainer {
  padding: 2rem;
  background-color: rgba(33, 33, 33, 100%);
  min-height: 88.5vh;
}

.onsCreateInner {
  display: flex;
  gap: 3rem;
}

.pin {
  width: 1rem;
  height: 1rem;
  border: 2px solid rgba(255, 238, 88, 100%);
  background-color: rgba(33, 33, 33, 100%);
  border-radius: 50%;
}

.activePin {
  width: 1rem;
  height: 1rem;
  border: 2px solid rgba(255, 238, 88, 100%);
  background-color: rgba(255, 238, 88, 100%);
  border-radius: 50%;
}

.noWayPoints {
  position: absolute;
  background-color: rgba(144, 202, 249, 16%);
  padding: 1rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 45%;
  left: 40%;
}

.listContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 62vh;
  overflow: scroll;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 50%);
  height: 3.5rem;
  padding: 0.5rem 0.75rem 0.5rem 0;
}

.listItem:hover {
  background-color: rgba(255, 255, 255, 10%);
}

.inputValue {
  color: rgba(255, 255, 255, 100%) !important;
}

.elevation {
  width: 5.3rem;
}

.elevation:hover,
.elevationNullable {
  outline: 1px solid rgba(255, 255, 255, 23%) !important;
  border-radius: 0.25rem;
}

.elevationValue,
.elevationNullable {
  color: rgba(255, 255, 255, 38%) !important;
}

.position {
  width: 8rem;
}

.position:hover {
  outline: 1px solid rgba(255, 255, 255, 23%) !important;
  border-radius: 0.25rem;
}

.positionFocused {
  outline: 2px solid rgba(144, 202, 249, 100%) !important;
  border-radius: 0.25rem;
}

.positionNullable {
  outline: 1px solid rgba(255, 255, 255, 23%) !important;
  border-radius: 0.25rem;
  width: 8rem;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
}

.listItemOrder {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.restoreBlock {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.elevationM {
  color: rgba(255, 255, 255, 38%) !important;
}

.deleteIcon {
  color: rgba(255, 255, 255, 56%) !important;
}

.dragIcon {
  color: rgba(255, 255, 255, 56%) !important;
  cursor: grab;
  margin-right: 0.5rem;
}

.locationIcon {
  color: rgba(255, 255, 255, 23%) !important;
  width: 0.75rem !important;
  height: 0.75rem !important;
}

.myRoutesLink {
  color: rgba(255, 255, 255, 70%);
  text-decoration: none;
}

.myRoutesLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.separator {
  color: rgba(255, 255, 255, 70%);
  cursor: auto;
}

.mapContainer {
  margin-top: 2rem;
  position: relative;
}

.map {
  width: 68vw;
  height: 82vh;
}

.previewMap {
  width: 100%;
  height: 97%;
}

.previewMap > div {
  border-radius: 20px;
  border: 4px solid rgba(232, 234, 246, 100%);
}

.map > div {
  border-radius: 20px;
  border: 4px solid rgba(232, 234, 246, 100%);
}

.notchedOutline {
  border: none !important;
}

.routeActionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 50%);
  padding: 32px 32px 0;
  bottom: 0;
}

.generateFileButton {
  background-color: rgba(144, 202, 249, 100%) !important;
  color: black !important;
}

.clearAllButton {
  color: rgba(144, 202, 249, 100%) !important;
}

.generateFileButtonDisabled {
  background-color: rgba(144, 202, 249, 50%) !important;
  color: black !important;
}

.clearAllButtonDisabled {
  color: rgba(144, 202, 249, 50%) !important;
}

.tableIcon {
  color: rgba(255, 255, 255, 56%) !important;
}

.toolbarRight {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
  min-width: 65%;
}

.toolbarRightSelected {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.toolbarRightButtons {
  color: rgba(0, 0, 0, 87%) !important;
}

.downloadBtn {
  background-color: rgba(144, 202, 249, 100%) !important;
  color: rgba(0, 0, 0, 87%) !important;
}

.datePickers {
  display: flex;
  justify-content: center;
  width: 40%;
  gap: 0.5rem;
}

.cellActions {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}

.editableTitleContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 16rem;
}

.advancedMarker {
  transform: translate(0, 50%);
}

.infoWindowWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  font-size: 1.2rem;
}

.centeredWidget {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 13.5rem;
  right: 1rem;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 0.25rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 #14151a0d;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15rem;
  max-width: fit-content;
  user-select: none;
}

.titleInNotification {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30rem;
  max-width: fit-content;
}

.noRoutesContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
}

.noRoutes {
  font-style: italic;
}
