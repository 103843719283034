.controlsVisualizationContainer {
  height: 8.25rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 14%);
  padding: 0.5rem;
  position: relative;
}

.fullWidthHeight {
  width: 100%;
  height: 100%;
}

.wheel {
  position: absolute;
  top: 3.125rem;
  left: 3.125rem;
  width: 4.375rem;
  height: 4.375rem;
}

.brake {
  position: absolute;
  top: 7.313rem;
  left: 5.875rem;
  width: 1.813rem;
  height: 1.438rem;
}

.accelerator {
  position: absolute;
  top: 5.938rem;
  left: 8.125rem;
  width: 1.438rem;
  height: 2.625rem;
}

.trawl {
  position: absolute;
  top: 0.5rem;
  right: 50%;
  transform: translateX(50%);
}

.gearControls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 85%;
  width: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 14%);
  border-radius: 0.25rem;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.stop {
  position: absolute;
  top: 4.063rem;
  right: 3rem;
  width: 1.125rem;
  height: 1.125rem;
}
