.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: 70vh;
  background-color: #fff;
  border: 1px solid #000;
  padding: 16px;
  border-radius: 10px;
}

.modalContainer:focus-visible {
  border: none;
  outline: none;
}

.modalCloseIcon {
  position: absolute !important;
  right: 1rem;
  z-index: 1;
}

.gamepadCardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 50px;
  border-bottom: 1px solid rgb(241, 242, 244);
}

.gamepadCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.connectGamepadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
}

.controlTypeContainer {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.controlType {
  padding: 20px 0 !important;
}
