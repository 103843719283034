.fullScreenModeActive {
  position: absolute;
  inset: 0;
  z-index: 1450;
  width: 100%;
  height: 100%;
}

.fullScreenModeInActive {
  display: none;
}

.singlePhotoZoomContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: fixed !important;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0.5rem;
  z-index: 9999;
}

.singlePhotoArrowForward {
  position: fixed !important;
  top: 50%;
  right: 0.5rem;
  background-color: #000 !important;
}

.titleFullScreen {
  position: fixed !important;
  top: 0.5rem;
  left: 0.5rem;
  background-color: #000 !important;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
}

.timestampFullScreen {
  position: fixed !important;
  top: 2rem;
  left: 0.5rem;
  background-color: #000 !important;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
}

.closeFullScreen {
  position: fixed !important;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #000 !important;
}

.singlePhotoArrowBack {
  position: fixed !important;
  top: 50%;
  left: 0.5rem;
  background-color: #000 !important;
}

.fullScreenMode {
  color: #fff;
}
