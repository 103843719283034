.loginHeaders {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 0;
  text-align: center;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.backToLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: #0a0f290a;
  cursor: pointer;
  position: absolute;
  left: 2.5rem;
}
