.controlItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  position: relative;
}

.commandText {
  position: absolute;
  bottom: -43%;
  color: #fff;
  font-size: 0.625rem !important;
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 5rem;
  max-width: fit-content;
}

.startIcon,
.pauseIcon,
.restartIcon,
.routIcon,
.resumeIcon,
.stopIcon {
  fill: #fff !important;
}

.disabledRestartIcon,
.disableResumeIcon {
  fill: #808080 !important ;
}

.missionStartButton {
  background-color: rgba(102, 187, 106, 100%) !important;
}

.activeMissionStartButton {
  background-color: rgb(255, 0, 0) !important;
}

.disabledMissionStartButton {
  background-color: rgb(128, 128, 128) !important;
}

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  max-height: 100vh;
  background-color: #fff;
  border: 1px solid #000;
  padding: 16px;
  border-radius: 10px;
}

.missionContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 70px;
}

.modalContainer:focus-visible {
  border: none;
  outline: none;
}

.modalCloseIcon {
  position: absolute !important;
  right: 1rem;
  z-index: 1;
}

.missionTextarea {
  white-space: 'pre-wrap';
  max-width: 1055px;
  margin: 50px 0 20px;
  border-radius: 2px;
  resize: none;
  width: 100%;
}

.missionInputWrapper {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.missionInput {
  cursor: pointer;
  position: absolute;
  height: 35px;
  font-size: 600px;
  opacity: 0;
  left: 0;
  bottom: 0;
}

.missionSendAndStartContainer {
  display: flex;
  flex-direction: column;
  align-items: self-end;
  gap: 5px;
}
