html {
  font-size: 16px;
}

body {
  margin: 0;
  overflow: hidden;
}

.leaflet-control-attribution {
  display: none;
}

.miniMapContainer.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.slidesMiniMapContainer.leaflet-container {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.leaflet-div-icon {
  background: none !important;
}

.backdrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 999;
}

.backdrop::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: #fff;
  opacity: 0.8;
  filter: blur(15px);
  z-index: 0;
}

.backdropMessage {
  margin-right: 1rem;
  position: relative;
  z-index: 10000;
}

.textDecorationNoneForDeviceLink {
  text-decoration: none;
  cursor: pointer;
  color: rgba(255, 255, 255, 70%);
}

.leaflet-div-icon {
  border: none !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.leaflet-div-icon svg {
  display: inline-block;
  transform: translate(-50%, -50%) !important;
}

.gm-style iframe + div {
  border: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
