.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(#ffc453, #3984f4);
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  max-width: 30rem;
  width: 100%;
  border-radius: 8px !important;
  position: relative;
}

.language {
  position: absolute;
  top: 1rem;
  right: 2rem;
  max-width: 195px;
  width: 100%;
}

.menuItemTypography {
  display: flex;
  gap: 10px;
}
