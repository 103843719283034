.vehiclesContainer {
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.vehiclesListContainer {
  width: 30rem;
  padding: 1.25rem 1.25rem 0;
  background-color: #292929;
}

.vehiclesListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  color: #fafafa;
}

.vehiclesListHeaderOption {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border: 1px solid #dee0e3;
  border-radius: 10px;
  cursor: pointer;
}

.vehiclesListLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  height: 100%;
}

.vehiclesList {
  display: flex;
  flex-direction: column;
  width: 26rem;
  gap: 0.5rem;
  height: 70vh;
  overflow-y: overlay;
}

.vehiclesDeviceContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.vehiclesDeviceWrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.vehiclesList::-webkit-scrollbar {
  width: 8px;
}

.vehiclesList::-webkit-scrollbar-track {
  background-color: #292929;
  border-radius: 10px;
}

.vehiclesList::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.vehiclesList::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.vehiclesListTextColor {
  color: #fafafa;
  margin: 0;
}

.vehiclesListItem {
  display: flex;
  flex-direction: column;
  align-items: start !important;
  background-color: #212121;
  box-shadow: 0 2px 4px -1px #0003;
  border-radius: 12px;
  gap: 0.5rem;
  padding: 8px 12px !important;
  cursor: pointer;
}

.vehiclesListItemActive {
  display: flex;
  flex-direction: column;
  align-items: start !important;
  background-color: #424242;
  box-shadow: 0 2px 4px -1px #0003;
  border-radius: 12px;
  gap: 0.5rem;
  padding: 8px 12px !important;
  transition: background-color ease 0.2s;
}

.deviceOnline {
  display: flex;
  gap: 2px;
  border: 1px solid #0a0f2914;
  background-color: #fdead8;
  border-radius: 8px;
  padding: 2px;
}

.deviceOffline {
  display: flex;
  gap: 2px;
  border: 1px solid #0a0f2914;
  background-color: #fff;
  border-radius: 8px;
  padding: 2px;
}

.vehicleStatus {
  max-width: 65px;
  width: 100%;
  padding: 3px 4px;
  border-radius: 8px;
}

.vehicleStatusColor {
  color: #fff !important;
}

.vehicleStatusOnline {
  background-color: #2196f3;
}

.vehicleStatusOffline {
  background-color: #424242;
}

.vehicleStatusOnMission {
  white-space: nowrap;
  background-color: #008000ff;
}

.vehicleConnectingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20rem;
}

.listUuid {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #bdbdbd;
}

.vehicleTypeIconContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 10rem;
  height: 20px;
  padding: 3px 10px 3px 3px;
  background: #424242;
  border-radius: 8px;
}

.vehicleTypeIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #212121;
  border-radius: 4px;
}

.commonControls {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: fit-content;
  height: 56px;
  background-color: rgba(45, 45, 45, 100%);
  border-radius: 50px;
  position: absolute;
  left: 43%;
  bottom: 1.5rem;
  transform: translate(-50%, 0);
  padding: 0 4px;
}

.devicePopupContainer {
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 6.5rem;
  right: 50%;
  transform: translate(50%);
  z-index: 1000;
  width: 90%;
  background-color: #212121;
  border-radius: 12px;
  padding: 24px;
}

.devicePopupContainerClosed {
  display: none;
}

.devicePopupContainerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 3rem;
}

.devicePopupControls {
  position: relative;
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.devicePopupIcons {
  padding: 0.51rem;
  border-radius: 4px;
  background-color: #424242;
  line-height: 0;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.devicePopupIcons:hover {
  background-color: #1565c0;
}

.devicePopupClose {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #757575;
  cursor: pointer;
}

.customIconClass {
  padding: 20px;
  background-color: yellow;
}

.miniMapCoordinatesContainer {
  display: flex;
  justify-content: center;
  gap: 0.3rem;
  cursor: pointer;
  width: 100%;
}

.controllerDisabled {
  cursor: not-allowed !important;
  color: #fff !important;
  background-color: #808080ff !important;
  pointer-events: auto !important;
}

.viewerDisabled {
  padding: 0.51rem;
  border-radius: 4px;
  line-height: 0;
  cursor: not-allowed !important;
  color: #fff !important;
  background-color: #808080ff !important;
  pointer-events: auto;
}
