.videoStreamContainer {
  display: grid;
  align-items: center;
}

.videoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
}

.thirdElement {
  justify-self: center;
  grid-column-start: span 3;
  width: 50%;
}

.loadingStatusContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.iconControls {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  z-index: 997;
  background-color: rgba(15, 19, 36, 60%);
  height: 3rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: fit-content;
}

.iconOptions {
  display: flex;
  gap: 0.5rem;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  z-index: 997;
}

.iconOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: rgba(15, 19, 36, 60%);
}

.videoRecordingInfo {
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  left: 0.8rem;
  z-index: 99999;
  opacity: 0.6;
  border-radius: 4px;
  padding: 0 4px;
  width: fit-content;
}

.redDot {
  width: 8px;
  height: 8px;
  background-color: #d9281c;
  border-radius: 50%;
}

.otherCameras {
  display: flex;
  gap: 0.5rem;
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
  z-index: 9999;
}

.videoStreamMedia {
  object-fit: contain;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.videoMessage {
  color: #fff !important;
}

svg {
  cursor: pointer;
}

.settingsContainer {
  position: relative;
}

.settingsListContainer {
  position: absolute;
  left: -5rem;
  bottom: 4rem;
  height: fit-content;
  width: 12.5rem;
  background-color: #0f132499;
  opacity: 0.6;
  z-index: 2;
  border-radius: 8px;
  padding: 8px;
}

.settingsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.settingsListItem {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1.5rem;
  height: 36px;
  padding: 4px 16px;
  cursor: pointer;
}
